<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <!--<div><router-link to="/Admin/adv/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>-->
                    <div><el-input v-model="where.F_VR_SHOUJHM" placeholder="请输入手机号码"></el-input></div>
                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>

                    <el-table-column label="反馈人" width="140px">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.F_VR_TOUX"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                <dd class="table_dl_dd_all" :title="scope.row.F_VR_NIC">{{ scope.row.F_VR_NIC }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_VR_SHOUJHM" label="手机号码"></el-table-column>
                    <el-table-column prop="F_TE_FANKNR" label="反馈内容"></el-table-column>
                    <el-table-column label="反馈图片" align="center" width="185px">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in scope.row.F_TE_FANKTP" :key="index">
                                <el-popover placement="left" trigger="click" width="600">
                                    <img :src="item" width="100%" />
                                    <img
                                            slot="reference"
                                            :src="item"
                                            :alt="item"
                                            style="max-height: 70px;max-width: 70px; padding: 5px"
                                    />
                                </el-popover>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="F_IN_CHUANGJSJ" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <!--<el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'adv_form',params:{id:scope.row.id}})">编辑</el-button>
                            &lt;!&ndash; <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> &ndash;&gt;
                        </template>
                    </el-table-column>-->
                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
          },
      };
    },
    watch: {},
    computed: {},
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getYijfkList,this.where).then(res=>{
                this.page_size = res.data.per_page;
                this.total_data = res.data.total;
                this.current_page = res.data.current_page;
                this.list = res.data.data;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delYijfk,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>